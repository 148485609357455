* {
  box-sizing: border-box;
}

i {
  line-height: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  background-color: #f4f4f4;
}

body {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  background-color: transparent;
}
a:focus {
  outline: 0;
}

a:active,
a:hover,
a:focus {
  outline-width: 0;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
button:focus,
button:hover [type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border: none;
  outline: none;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: none;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

div,
span,
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif;
}

.frame img {
  position: absolute;
  height: 450px;
  width: 800px;
  opacity: 0;
}

.logo-container {
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: end;
}
.logo-container img {
  width: 200px;
  height: 196px;
}

.nav-container {
  height: 100px;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 999;
}
.nav-container .nav-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-container .nav-item {
  display: flex;
  justify-content: center;
  width: 150px;
  margin: 0 12px 0;
  color: #973407;
  font-size: 30px;
  font-weight: 800;
  cursor: pointer;
  position: relative;
}
.nav-container .nav-item .frame img {
  right: -42px;
  top: -8px;
  height: 54px;
  width: 96px;
  pointer-events: none;
}
.nav-container .nav-item:hover {
  color: #7a2904;
}
.nav-container .logo {
  display: none;
}
.nav-container.sticky {
  background: rgb(255, 145, 26);
  background: linear-gradient(0deg, rgba(255, 145, 26, 0) 0%, rgb(255, 141, 0) 100%);
}
.nav-container.sticky .nav-item {
  font-size: 40px;
  font-weight: 900;
  width: 200px;
}
.nav-container.sticky .nav-item .frame img {
  right: -66px;
  top: -20px;
  height: 81px;
  width: 144px;
}
.nav-container.sticky .logo {
  display: flex;
  margin: 0 18px 0;
  width: 100px;
  height: 98px;
  padding: 0;
  cursor: default;
  font-size: 12px;
}

.landing-root {
  background-image: linear-gradient(to right bottom, #ff911a, #e5a500, #c6b600, #a4c422, #7dcf48);
}

.title-container {
  margin: 60px 0 0;
  position: relative;
  height: 350px;
  /*.row {
    position: relative;
  }*/
}
.title-container h1 {
  text-align: center;
  color: #973407;
  font-weight: 900;
}
.title-container .r1 {
  font-size: 20px;
  line-height: 1;
  text-shadow: 0.5px 1px #000000;
}
.title-container .r2 {
  font-size: 40px;
  line-height: 20px;
  text-shadow: 1px 2px #000000;
}
.title-container .r3 {
  font-size: 80px;
  line-height: 58px;
  text-shadow: 2px 4px #000000;
}
.title-container .r4 {
  font-size: 120px;
  line-height: 73px;
  text-shadow: 3px 6px #000000;
}
.title-container .frame.anim-1 img {
  width: 320px;
  height: 180px;
  transform: translateX(-50%) scaleX(-1);
  left: 55%;
  bottom: 0;
}
.title-container .frame.anim-2 img {
  width: 320px;
  height: 180px;
  transform: translateX(-50%);
  left: 45%;
  bottom: 0;
}

.games-root {
  padding: 96px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.games-root h3 {
  color: #973407;
  font-size: 80px;
  text-shadow: 2px 4px #000000;
  font-weight: 800;
}
.games-root iframe {
  margin: 32px 0 0;
  border: none;
  border-radius: 12px;
}
.games-root .link {
  margin: 32px 0 0;
  display: flex;
  justify-content: center;
}
.games-root img {
  width: 30%;
  border-radius: 12px;
}

.contact-root {
  padding: 96px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-root h3 {
  color: #973407;
  font-size: 80px;
  text-shadow: 2px 4px #000000;
  font-weight: 800;
}
.contact-root p {
  padding: 18px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  max-width: 592px;
}
.contact-root .link {
  color: #973407;
  font-weight: 900;
}

.footer-root {
  padding: 96px 0;
  display: flex;
  justify-content: center;
}
.footer-root .link {
  margin: 0 4px;
}
.footer-root .link img {
  width: 32px;
  height: 32px;
}

/*

@import "components/icons";
@import "components/button";
@import "components/input";
@import "components/lightbox";
@import "components/loader";

@import "layout/footer";



*/

