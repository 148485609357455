.landing-root {
  background-image: linear-gradient(to right bottom, #ff911a, #e5a500, #c6b600, #a4c422, #7dcf48);
}

.title-container {
  margin: 60px 0 0;
  position: relative;
  height: 350px;

  h1 {
    text-align: center;
    color: #973407;
    font-weight: 900;
  }

  /*.row {
    position: relative;
  }*/

  .r1 {
    font-size: 20px;
    line-height: 1;
    text-shadow: 0.5px 1px #000000;
  }
  .r2 {
    font-size: 40px;
    line-height: 20px;
    text-shadow: 1px 2px #000000;
  }
  .r3 {
    font-size: 80px;
    line-height: 58px;
    text-shadow: 2px 4px #000000;
  }
  .r4 {
    font-size: 120px;
    line-height: 73px;
    text-shadow: 3px 6px #000000;
  }

  .frame {
    &.anim-1 {
      img {
        width: 320px;
        height: 180px;
        transform: translateX(-50%) scaleX(-1);
        left: 55%;
        bottom: 0;
      }
    }

    &.anim-2 {
      img {
        width: 320px;
        height: 180px;
        transform: translateX(-50%);
        left: 45%;
        bottom: 0;
      }
    }
  }
}

.games-root {
  padding: 96px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3{
    color: #973407;
    font-size: 80px;
    text-shadow: 2px 4px #000000;
    font-weight: 800;
  }

  iframe {
    margin: 32px 0 0;
    border: none;
    border-radius: 12px;
  }

  .link {
    margin: 32px 0 0;
    display: flex;
    justify-content: center;
  }

  img {
    width: 30%;
    border-radius: 12px;
  }
}

.contact-root {
  padding: 96px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3{
    color: #973407;
    font-size: 80px;
    text-shadow: 2px 4px #000000;
    font-weight: 800;
  }

  p {
    padding: 18px;
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    max-width: 592px;
  }

  .link {
    color: #973407;
    font-weight: 900;
  }
}

.footer-root {
  padding: 96px 0;
  display: flex;
  justify-content: center;

  .link {
    margin: 0 4px;
    img {
      width: 32px;
      height: 32px;
    }
  }
}