


.header-root{

}

.logo-container {
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: end;

  img {
    width: 200px;
    height: 196px;
  }
}

.nav-container {
  height: 100px;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  z-index: 999;

  .nav-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-item {
    display: flex;
    justify-content: center;
    width: 150px;
    margin: 0 12px 0;
    color: #973407;
    font-size: 30px;
    //text-shadow: 1px 2px #000000;
    font-weight: 800;
    cursor: pointer;
    position: relative;

    .frame {
      img {
        right: -42px;
        top: -8px;
        height: 54px;
        width: 96px;
        pointer-events: none;
      }
    }

    &:hover {
      color: #7a2904;
    }
  }

  .logo {
    display: none;

  }

  &.sticky {
    background: rgba(255, 145, 26);
    background: linear-gradient(0deg, rgba(255, 145, 26,0) 0%, rgba(255,141,0,1) 100%);

    .nav-item {
      font-size: 40px;
      font-weight: 900;
      width: 200px;

      .frame {
        img {
          right: -66px;
          top: -20px;
          height: 81px;
          width: 144px;
        }
      }
    }

    .logo {
      display: flex;
      margin: 0 18px 0;
      width: 100px;
      height: 98px;
      padding: 0;
      cursor: default;
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 768px) {

}