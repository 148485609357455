* {
    box-sizing: border-box;
}

i {
    line-height: 0;
}

html {
    -webkit-text-size-adjust: 100%;
    background-color: #f4f4f4;
}

body {
    margin: 0;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

p {
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;
    background-color: transparent;
    &:focus {
        outline: 0;
    }
}

a:active,
a:hover,
a:focus {
    outline-width: 0;
}

img {
    border-style: none;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    /* 1 */
    font-size: 100%;
    /* 1 */
    margin: 0;
    /* 2 */
}

button,
select {
    /* 1 */
    text-transform: none;
}


button,
[type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
button:focus,
button:hover [type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border: none;
    outline: none;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: none;
}

textarea {
    overflow: auto;
}

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    /* 1 */
    padding: 0;
    /* 2 */
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    -webkit-appearance: textfield;
    /* 1 */
    outline-offset: -2px;
    /* 2 */
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    /* 1 */
    font: inherit;
    /* 2 */
}

div,
span,
input,
textarea,
button,
select,
a {
    -webkit-tap-highlight-color: transparent;
}